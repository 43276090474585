import React, { useContext } from 'react';
import classNames from 'classnames';
import { DarkContext } from 'components/ui-components/Dark';
import '../styles/sidebar-actionbar.scss';

interface Props {
    children: React.ReactNode | React.ReactNode[];
}

const FullScreenOverlaySidebarActionbar = ({ children }: Props) => {
    const dark = useContext(DarkContext);
    return (
        <div
            className={classNames('full-screen-overlay-sidebar-actionbar', {
                'full-screen-overlay-sidebar-actionbar--dark': dark
            })}>
            {children}
        </div>
    );
};

export default FullScreenOverlaySidebarActionbar;
