import React from 'react';
import AccordionSummary from 'components/ui-components-v2/AccordionSummary';
import AccordionDetails from 'components/ui-components-v2/AccordionDetails';
import Accordion from 'components/ui-components-v2/Accordion';
import Icon from 'components/ui-components-v2/Icon';
import Typography from 'components/ui-components-v2/Typography';
import GenericFilterType from './type';
import GenericFilterTypeNavigation from './type-navigation';

/**
 * Display one expandable filter in the filter list.
 * @param {*} param0
 * @returns
 */
const GenericFilterFilter = ({ filter, defaultExpanded, value, maxOptionsShown, onChange }) => {
    if (filter.type === 'navigation') return <GenericFilterTypeNavigation filter={filter} value={value} />;

    return (
        <Accordion square disableGutters defaultExpanded={defaultExpanded}>
            <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography variant="h4" component="p">
                    {filter.label}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <GenericFilterType filter={filter} value={value} maxOptionsShown={maxOptionsShown} onChange={onChange} />
            </AccordionDetails>
        </Accordion>
    );
};

export default GenericFilterFilter;
