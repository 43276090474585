import React, { useState } from 'react';
import classNames from 'classnames';
import TextField from 'components/ui-components-v2/TextField';
import Autocomplete from 'components/ui-components-v2/Autocomplete';
import Translation from 'components/data/Translation';
import GenericFilterDelete from './delete';

import '../styles/type-autocomplete.scss';

/**
 * Display a filter of type autocomplete in the vertical list with one option selectable
 * @param {*} param0
 * @returns
 */
const GenericFilterTypeAutocomplete = ({ filter, value = null, className, onChange }) => {
    /**
     * Get an option label
     * @param {string} option
     * @returns
     */
    const getOptionLabel = (option) => {
        if (option) {
            const thisOption = filter.options.find((o) => o.key == option);
            if (thisOption.value) return thisOption.value.trim();
        }
        return '';
    };

    const [filterOptions] = useState(Array.isArray(filter.options) ? filter.options.map((o) => o.key) : []);

    return (
        <div className={classNames('generic-filter__type-autocomplete', className)}>
            <Autocomplete
                id={'autocomplete-' + filter.name}
                options={filterOptions}
                onChange={(e, value) => {
                    onChange(filter.name, value);
                }}
                classes={{
                    root: 'generic-filter__type-autocomplete__root',
                    inputRoot: 'generic-filter__type-autocomplete__input-root',
                    input: 'generic-filter__type-autocomplete__input',
                    paper: 'generic-filter__type-autocomplete__paper',
                    option: 'generic-filter__type-autocomplete__option',
                    popper: 'generic-filter__type-autocomplete__popper'
                }}
                fullWidth
                openOnFocus
                blurOnSelect
                disableClearable
                disablePortal
                value={value}
                getOptionLabel={(option) => getOptionLabel(option)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={Translation.get('filters.typeSelect', 'ui-base')}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps
                        }}
                    />
                )}
                renderOption={(option) => <React.Fragment>{getOptionLabel(option)}</React.Fragment>}
            />
            <GenericFilterDelete onDelete={() => onChange(filter.name, '')} active={value && value.length > 0} />
        </div>
    );
};

export default GenericFilterTypeAutocomplete;
