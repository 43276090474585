import React from 'react';
import classNames from 'classnames';
import Dark, { DarkProvider } from 'components/ui-components/Dark';
import '../styles/sidebar.scss';

interface Props {
    show?: boolean;
    dark?: boolean;
    tourData?: string;
    children: React.ReactNode | React.ReactNode[];
}

const FullScreenOverlaySidebar = ({ show, dark, children, tourData }: Props) => {
    if (!show) {
        return <React.Fragment />;
    }
    return (
        <DarkProvider value={Boolean(dark)}>
            <Dark dark={dark}>
                <div
                    data-mui-color-scheme={dark ? 'dark' : 'light'}
                    className={classNames('full-screen-overlay-sidebar', { 'full-screen-overlay-sidebar--dark': dark })}
                    // eslint-disable-next-line react/no-unknown-property
                    tour-data={tourData}>
                    {children}
                </div>
            </Dark>
        </DarkProvider>
    );
};

export default FullScreenOverlaySidebar;
