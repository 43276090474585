import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slide from '@mui/material/Slide';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Icon from 'components/ui-components-v2/Icon';
import SearchField from 'components/ui-components/SearchField';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import GenericFilterList from './list';
import GenericFilterHorizontal from './horizontal';
import GenericFilterChips from './chips';
import GenericFilterActions from './actions';

import '../styles/main.scss';

/**
 * Display the Generic Filter
 */
class GenericFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterSetupHorizontal: this.getFilterSetup(true, props.showHorizontal, props.filterSetup),
            filterSetupList: this.getFilterSetup(false, props.showHorizontal, props.filterSetup),
            showFilters: false
        };
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.filtersLoading !== this.props.filtersLoading ||
            prevProps.filterSetup !== this.props.filterSetup ||
            prevProps.showHorizontal !== this.props.showHorizontal
        ) {
            this.setState({
                filterSetupHorizontal: this.getFilterSetup(true, this.props.showHorizontal, this.props.filterSetup),
                filterSetupList: this.getFilterSetup(false, this.props.showHorizontal, this.props.filterSetup)
            });
        }
    }

    /**
     * Update the filters set and propagate to the parent component via onMutation.
     * If legacy props onSearch or onChangeFilter are provided. Use these.
     * @param {string} key
     * @param {string} value
     */
    onChange = (key, value) => {
        const { onMutation, onSearch, onChangeFilter, filters } = this.props;

        if (key === 'searchTerm' && onSearch) {
            onSearch(value);
        } else if (onChangeFilter) {
            onChangeFilter(key, value);
        } else if (onMutation) {
            const newFilters = { ...filters };
            if (!!value && value.length > 0) {
                newFilters[key] = value;
                onMutation(newFilters);
            } else {
                delete newFilters[key];
                onMutation(newFilters);
            }
        } else {
            console.error('No function provided for filter change');
        }
    };

    /**
     * Clear all set filters
     * If a specific onClearAll funtion is provided use that.
     * Otherwise do an onMutation with an empty filter object.
     */
    onClearAll = () => {
        const { onClearAll, onMutation } = this.props;

        if (onClearAll) {
            onClearAll();
        } else if (onMutation) {
            onMutation({});
        } else {
            console.error('No function provided for clear all filters');
        }
    };

    /**
     * Determine the filters to show in the list or on the bar (horizontal).
     * @param {boolean} horizontal
     */
    getFilterSetup = (horizontal, showHorizontal, filterSetup) => {
        let newFilterSetup = [];
        if (horizontal) {
            newFilterSetup = showHorizontal > 0 ? [...filterSetup.filter((item) => !item.hidden)].slice(0, showHorizontal) : [];
        } else {
            newFilterSetup =
                showHorizontal > 0 ? [...filterSetup.filter((item) => !item.hidden)].slice(showHorizontal) : [...filterSetup.filter((item) => !item.hidden)];
        }
        return newFilterSetup;
    };

    getFiltersCount = () => {
        const { filters, ignoreInFiltersCount = [] } = this.props;
        const { filterSetupHorizontal } = this.state;
        let count = 0;

        Object.keys(filters).forEach((filterKey) => {
            // Return if the key is a number or a string with a number (e.g. 2 or '2')
            if (!isNaN(Number(filterKey))) return;

            // Return if this key should be ignored in the filtersCount
            if (ignoreInFiltersCount.includes(filterKey)) return;

            // Ignore horizontal filters
            if (filterSetupHorizontal.filter((e) => e.name === filterKey).length > 0) return;

            if (filters[filterKey] && filterKey !== 'searchTerm') {
                // If filter is an array add array length to count. Else increment count by 1.
                if (Array.isArray(filters[filterKey])) {
                    count += filters[filterKey].length;
                } else {
                    count += 1;
                }
            }
        });

        return count;
    };

    /**
     * Copy the currently set filters to the users clipboard.
     */
    copyFilters = () => {
        const { filters } = this.props;
        try {
            navigator.clipboard.writeText(JSON.stringify(filters, null, 2));
            SnackbarUtils.success(Translation.get('filters.copied', 'ui-base'));
        } catch (e) {
            SnackbarUtils.error(Translation.get('filters.copyFailed', 'ui-base'));
        }
    };

    render() {
        const { showFilters, filterSetupList, filterSetupHorizontal } = this.state;
        const {
            left,
            inDialog,
            filters,
            filterSetup,
            maxOptionsShown,
            showHorizontal,
            expandAllFilters,
            filtersLoading,
            totalItemsCount,
            filteredItemsCount,
            searchField,
            searchTerm,
            searchPlaceholder,
            forceSearchTerm,
            alternativeSearch,
            children,
            actions,
            standAlone,
            fixedList,
            hideChips,
            canCopyFilters,
            autoCompleteOptionsLength,
            className,
            buttonVariant,
            classes
        } = this.props;

        if (fixedList) {
            return (
                <GenericFilterList
                    searchField={searchField}
                    searchTerm={searchTerm}
                    searchPlaceholder={searchPlaceholder}
                    filterSetup={filterSetupList}
                    filters={filters}
                    forceSearchTerm={forceSearchTerm}
                    maxOptionsShown={maxOptionsShown}
                    expandAllFilters={expandAllFilters}
                    filtersLoading={filtersLoading}
                    hideHeader
                    onChange={this.onChange}
                    className={className}
                />
            );
        }

        const filtersCount = this.getFiltersCount();

        return (
            <React.Fragment>
                {standAlone && filterSetupList.length > 0 && !filtersLoading && (
                    <Button
                        variant={buttonVariant}
                        color="primary"
                        onClick={() => this.setState({ showFilters: !showFilters })}
                        endIcon={<Icon>filter_list</Icon>}
                        className={classNames('generic-filter__button', className)}>
                        {Translation.get('filters.filters', 'ui-base')}
                        {filtersCount > 0 && <div className="generic-filter__button__badge">{filtersCount}</div>}
                    </Button>
                )}
                {!standAlone && (
                    <div className={classNames('generic-filter', className)} data-tour-selector="generic-filter">
                        <div className={classNames('generic-filter__bar', classes?.bar)}>
                            {searchField && (
                                <SearchField
                                    searchTerm={searchTerm}
                                    onChange={this.onChange}
                                    searchPlaceholder={searchPlaceholder}
                                    forceSearchTerm={forceSearchTerm}
                                    className={classNames('generic-filter__search', classes?.search, {
                                        'generic-filter__search--dialog': inDialog
                                    })}
                                />
                            )}
                            {alternativeSearch && !searchField && alternativeSearch}
                            {(filterSetupList.length > 0 || filterSetupHorizontal.length > 0 || !!children || !!actions) && (
                                <div className="generic-filter__right">
                                    {filterSetupHorizontal.length > 0 && !filtersLoading && (
                                        <GenericFilterHorizontal
                                            filterSetup={filterSetupHorizontal}
                                            filters={filters}
                                            maxOptionsShown={maxOptionsShown}
                                            onChange={this.onChange}
                                            autoCompleteOptionsLength={autoCompleteOptionsLength}
                                        />
                                    )}
                                    {filterSetupList.length > 0 && !filtersLoading && (
                                        <Button
                                            variant={buttonVariant}
                                            color="primary"
                                            onClick={() => this.setState({ showFilters: !showFilters })}
                                            endIcon={<Icon>filter_list</Icon>}
                                            className="generic-filter__button">
                                            {showHorizontal > 0 ? 'More filters' : 'Filters'}
                                            {filtersCount > 0 && <div className="generic-filter__button__badge">{filtersCount}</div>}
                                        </Button>
                                    )}
                                    {(!!children || !!actions) && (
                                        <div className="generic-filter__children">
                                            {!!children && children}
                                            {!!actions && <GenericFilterActions actions={actions} />}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {!hideChips && (
                            <GenericFilterChips
                                classes={classes}
                                filterSetup={filterSetup}
                                filters={filters}
                                filtersLoading={filtersLoading}
                                totalItemsCount={totalItemsCount}
                                filteredItemsCount={filteredItemsCount}
                                canCopyFilters={canCopyFilters && Object.keys(filters) && Object.keys(filters.length > 0)}
                                onCopyFilters={() => this.copyFilters()}
                                onChange={this.onChange}
                                onClearAll={this.onClearAll}
                            />
                        )}
                    </div>
                )}
                {!filtersLoading && filterSetupList.length > 0 && showFilters && (
                    <Slide direction={left ? 'right' : 'left'} in={showFilters} mountOnEnter unmountOnExit>
                        <div
                            className={classNames('generic-filter__drawer', {
                                'generic-filter__drawer--left': left,
                                'generic-filter__drawer--dialog': inDialog
                            })}>
                            <ClickAwayListener onClickAway={() => this.setState({ showFilters: false })}>
                                <div>
                                    <GenericFilterList
                                        filterSetup={filterSetupList}
                                        filters={filters}
                                        maxOptionsShown={maxOptionsShown}
                                        expandAllFilters={expandAllFilters}
                                        filtersLoading={filtersLoading}
                                        inDialog={inDialog}
                                        onChange={this.onChange}
                                        onClose={() => this.setState({ showFilters: false })}
                                    />
                                </div>
                            </ClickAwayListener>
                        </div>
                    </Slide>
                )}
            </React.Fragment>
        );
    }
}

GenericFilter.propTypes = {
    left: PropTypes.bool,
    inDialog: PropTypes.bool,
    filterSetup: PropTypes.array,
    filters: PropTypes.object,
    maxOptionsShown: PropTypes.number,
    showHorizontal: PropTypes.number,
    expandAllFilters: PropTypes.bool,
    filtersLoading: PropTypes.bool,
    totalItemsCount: PropTypes.number,
    filteredItemsCount: PropTypes.number,
    onMutation: PropTypes.func,
    searchField: PropTypes.bool,
    hideChips: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    actions: PropTypes.array,
    standAlone: PropTypes.bool,
    canCopyFilters: PropTypes.bool,
    ignoreInFiltersCount: PropTypes.array,
    buttonVariant: PropTypes.oneOf(['text', 'outlined', 'contained'])
};

GenericFilter.defaultProps = {
    left: false,
    inDialog: false,
    filterSetup: [],
    filters: {},
    maxOptionsShown: 6,
    showHorizontal: 0,
    expandAllFilters: false,
    filtersLoading: false,
    searchField: false,
    hideChips: false,
    searchPlaceholder: Translation.get('filters.searchTerm', 'ui-base'),
    ignoreInFiltersCount: [],
    buttonVariant: 'outlined'
};

export default GenericFilter;
