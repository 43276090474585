import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import '../styles/show-more.scss';

/**
 * Display a show more button for a filter.
 * Used to toggle showing all the options or just a limited number.
 * @param {*} param0
 * @returns
 */
const GenericFilterShowMore = ({ showMore, onToggleShowMore }) => {
    return (
        <div className="generic-filter__show-more" onClick={() => onToggleShowMore(!showMore)}>
            <Icon className="generic-filter__show-more__icon">{showMore ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
            {showMore ? <span>{Translation.get('filters.showLess', 'ui-base')}</span> : <span>{Translation.get('filters.showMore', 'ui-base')}</span>}
        </div>
    );
};

export default GenericFilterShowMore;
