import React, { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import Icon from 'components/ui-components-v2/Icon';
import Slider from 'components/ui-components-v2/Slider';
import Button from 'components/ui-components-v2/Button';

import '../styles/type-range.scss';

const GenericFilterTypeRange = ({ filter, value, onChange }) => {
    const [range, setRange] = useState([0, 0]);
    const [min, setMin] = useState();
    const [max, setMax] = useState();
    const [unit, setUnit] = useState();
    const [slider, setSlider] = useState();

    const handleChange = (e) => {
        const value = Number(e.target.value);
        const newRange = [...range];
        console.log(e.target.name, value, typeof value);
        if (e.target.name === 'range_0') {
            newRange[0] = value;
        } else if (e.target.name === 'range_1') {
            newRange[1] = value;
        }
        setRange(newRange);
    };

    const applyFilter = () => {
        const newRange = [...range];

        if (newRange[1] <= newRange[0]) {
            newRange[1] = newRange[0] + 1;
            setRange(newRange);
        }

        onChange(filter.name, newRange);
    };

    useEffect(() => {
        let newMin = 0;
        let newMax = 0;
        let newUnit = null;
        let newSlider = false;

        if (filter.config) {
            if (filter.config.percentage) {
                newMin = 0;
                newMax = 100;
                newUnit = '%';
                newSlider = true;
            } else {
                if (filter.config['min']) newMin = filter.config.min;
                if (filter.config['max']) newMax = filter.config.max;
                if (filter.config['unit']) newUnit = filter.config.unit;
                if (!!newMax && newMax <= 1000) newSlider = true;
            }
            setMin(newMin);
            setMax(newMax);
            setUnit(newUnit);
            setSlider(newSlider);
            if (Array.isArray(value)) {
                setRange(value);
            } else {
                if (newSlider) {
                    setRange([newMin, newMax]);
                } else {
                    setRange([0, 0]);
                }
            }
        }
    }, []);

    const valuetext = (value) => {
        return `${value}${unit}`;
    };

    const getStep = (max) => {
        if (max <= 499) return 1;
        if (max <= 999) return 5;
        if (max <= 9999) return 10;
        return 100;
    };

    return (
        <div className="generic-filter__type-range">
            {slider ? (
                <div className="generic-filter__type-range__slider">
                    <div className="generic-filter__type-range__value generic-filter__type-range__value--min">{valuetext(range[0])}</div>
                    <Slider min={min} max={max} value={range} step={getStep(max)} onChange={(e, value) => setRange(value)} onChangeCommitted={applyFilter} />
                    <div className="generic-filter__type-range__value generic-filter__type-range__value--max">{valuetext(range[1])}</div>
                </div>
            ) : (
                <div className="generic-filter__type-range__inputs">
                    <OutlinedInput
                        name="range_0"
                        margin="dense"
                        type="number"
                        value={range[0]}
                        onChange={(e) => handleChange(e)}
                        placeholder="0"
                        endAdornment={unit && <span className="generic-filter__type-range__field__unit">{unit}</span>}
                        classes={{
                            input: 'generic-filter__type-range__field'
                        }}
                    />
                    <div className="generic-filter__type-range__divider">to</div>
                    <OutlinedInput
                        name="range_1"
                        margin="dense"
                        type="number"
                        value={range[1]}
                        onChange={(e) => handleChange(e)}
                        placeholder="0"
                        endAdornment={unit && <span className="generic-filter__type-range__field__unit">{unit}</span>}
                        classes={{
                            input: 'generic-filter__type-range__field'
                        }}
                    />
                    <Button color="primary" variant="contained" onClick={applyFilter} className="generic-filter__type-range__btn" size="small">
                        <Icon>refresh</Icon>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default GenericFilterTypeRange;
