import React from 'react';
import classNames from 'classnames';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Select from 'components/ui-components-v2/Select';

import '../styles/type-horizontal-select.scss';

/**
 * Display a filter of type select in the horizontal bar with one option selectable
 * @param {*} param0
 * @returns
 */
const GenericFilterTypeHorizontalSelect = ({ filter, value = '', onChange, className }) => {
    return (
        <FormControl variant="outlined" className={classNames('generic-filter__type-horizontal-select', className)}>
            <InputLabel margin="dense" htmlFor={'horizontal-select-' + filter.name}>
                {filter.label}
            </InputLabel>
            <Select
                data-tour-selector={'filter-' + filter.label}
                margin="dense"
                value={value}
                label={filter.label}
                onChange={(e) => onChange(filter.name, e.target.value)}
                inputProps={{
                    id: 'select-horizontal' + filter.name
                }}
                classes={{
                    select: 'generic-filter__type-horizontal-select__select'
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }}>
                {filter.options.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default GenericFilterTypeHorizontalSelect;
