import React from 'react';
import classNames from 'classnames';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Checkbox from 'components/ui-components-v2/Checkbox';
import Select from 'components/ui-components-v2/Select';

import '../styles/type-horizontal-select-multiple.scss';

/**
 * Display a filter of type select in the horizontal bar with multiple options selectable
 * @param {*} param0
 * @returns
 */

const GenericFilterTypeHorizontalSelectMultiple = ({ filter, value = [], className, onChange }) => {
    const showItem = (selected) => {
        let result = '';

        try {
            selected.map((key) => (result = result + ', ' + filter.options.filter((item) => item.key == key)[0].value));
            result = result.substring(2);
        } catch (e) {}

        if (result.length > 20) {
            result = result.substring(0, 20) + '..';
        }

        return result;
    };

    return (
        <FormControl variant="outlined" className={classNames('generic-filter__type-horizontal-select-multiple', className)}>
            <InputLabel margin="dense" htmlFor={'horizontal-select-multiple-' + filter.name}>
                {filter.label}
            </InputLabel>
            <Select
                margin="dense"
                multiple
                label={filter.label}
                value={value}
                onChange={(e) => onChange(filter.name, e.target.value)}
                renderValue={(selected) => showItem(selected)}
                inputProps={{
                    id: 'horizontal-select-multiple-' + filter.name
                }}
                classes={{
                    select: 'generic-filter__type-horizontal-select-multiple__select'
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }}>
                {filter.options.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        <Checkbox size="small" checked={value.indexOf(option.key) > -1} />
                        <ListItemText primary={option.value} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default GenericFilterTypeHorizontalSelectMultiple;
