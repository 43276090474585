import React, { useMemo } from 'react';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Chip from 'components/ui-components-v2/Chip';
import Icon from 'components/ui-components-v2/Icon';
import cloneDeep from 'helpers/cloneDeep';
import Translation from 'components/data/Translation';

import '../styles/chips.scss';

/**
 * Find a specific filter in the filter setup.
 * @param {string} filterName
 * @param {array} filterSetup
 * @returns one filter object
 */
const findFilter = (filterName, filterSetup) => {
    return filterSetup.find((f) => f.name === filterName);
};

/**
 * Build an array of filter values that have been set.
 * A filter value can be a single string value, or an array of string values.
 * @param {object} filters
 * @param {array} filterSetup
 * @returns
 */
const getChips = (filters, filterSetup) => {
    const chips = [];
    Object.entries(filters).forEach(([key, value]) => {
        if (key !== 'searchTerm') {
            const thisFilter = findFilter(key, filterSetup);
            if (!!thisFilter && thisFilter.type === 'selectMultiple' && Array.isArray(value)) {
                value.forEach((v) => {
                    chips.push({
                        name: key,
                        value: v,
                        label: getChipTitle(v, thisFilter)
                    });
                });
            } else if (!!thisFilter && thisFilter.type === 'selectMultipleSubs' && Array.isArray(value)) {
                value.forEach((v) => {
                    chips.push({
                        name: key,
                        value: v,
                        label: getChipTitleSubs(v, thisFilter)
                    });
                });
            } else if (!!thisFilter && thisFilter.type === 'range' && Array.isArray(value) && value.length === 2) {
                let unit = '';
                if (thisFilter.config) {
                    if (thisFilter.config.percentage) {
                        unit = '%';
                    } else if (thisFilter.config.unit) {
                        unit = thisFilter.config.unit;
                    }
                }
                chips.push({
                    name: key,
                    value,
                    label: getChipTitle(`${value[0]}${unit} - ${value[1]}${unit}`, thisFilter)
                });
            } else if (!!thisFilter && thisFilter.type === 'select') {
                if (value && value !== '') {
                    chips.push({
                        name: key,
                        value,
                        label: getChipTitle(value, thisFilter)
                    });
                }
            } else if (!!thisFilter && thisFilter.type === 'datePicker') {
                if (value) {
                    chips.push({
                        name: key,
                        value,
                        label: getChipTitle(value, thisFilter)
                    });
                }
            }
        }
    });

    return chips;
};

/**
 * Get the human readable title for a filter that has been set.
 * @param {string} value
 * @param {thisFilter} filter
 * @returns a title to display in the chip.
 */
const getChipTitle = (value, thisFilter) => {
    let title = '';

    if (thisFilter) {
        if (thisFilter.options) {
            const selectedOption = thisFilter.options.find((o) => o.key === value);
            if (selectedOption) title += selectedOption.value;
        } else {
            title += value;
        }
    }

    return title;
};

const getChipTitleSubs = (value, thisFilter) => {
    let title = '';
    const vArray = value.split('.');

    if (thisFilter) {
        if (thisFilter.options) {
            const selectedOption = thisFilter.options.find((o) => o.key === vArray[0]);
            if (selectedOption) title += selectedOption.value;
            if (vArray[1]) {
                const selectedSubOption = selectedOption.subOptions.find((so) => so.key === value);
                if (selectedSubOption) title += ` / ${selectedSubOption.value}`;
            }
        } else {
            title += `: ${value}`;
        }
    }

    return title;
};

/**
 * Display the filter values that have been set as chips.
 * @param {*} param0
 * @returns
 */
const GenericFilterChips = ({
    filters = {},
    filterSetup = [],
    filtersLoading,
    totalItemsCount,
    filteredItemsCount,
    canCopyFilters,
    className,
    onCopyFilters,
    onChange = () => {},
    onClearAll = () => {},
    classes = {}
}) => {
    const chips = useMemo(() => getChips(filters, filterSetup), [Object.keys(filters).length, filters, filterSetup]);
    /**
     * Remove a filter value, represented by a chip.
     * @param {object} chip
     */
    const removeFilter = (chip) => {
        const thisFilters = cloneDeep(filters);
        const thisValue = thisFilters[chip.name];
        const thisFilter = findFilter(chip.name, filterSetup);
        if (thisValue) {
            if (Array.isArray(thisValue) && (thisFilter.type === 'selectMultiple' || thisFilter.type === 'selectMultipleSubs')) {
                const index = thisValue.indexOf(chip.value);
                if (index > -1) {
                    thisValue.splice(index, 1);
                    onChange(chip.name, thisValue);
                }
            } else {
                onChange(chip.name, undefined);
            }
        }
    };

    const hasChips = Array.isArray(chips) && chips.length > 0;

    return (
        <div
            className={classNames('generic-filter__chips', className, classes?.chips, {
                'generic-filter__chips--empty': !hasChips && !canCopyFilters
            })}>
            <div className="generic-filter__chips__content">
                <div>
                    {hasChips && !filtersLoading && (
                        <React.Fragment>
                            {totalItemsCount !== undefined && filteredItemsCount !== undefined && (
                                <div className="generic-filter__chips__count">
                                    {Translation.get('filters.count', 'ui-base', { count: filteredItemsCount, total: totalItemsCount })}
                                </div>
                            )}
                            {chips.map((chip) => (
                                <Chip
                                    key={`filter-${chip.name}-${chip.value}`}
                                    size="small"
                                    className="generic-filter__chips__chip"
                                    icon={chip.icon ? <Icon>{chip.icon}</Icon> : undefined}
                                    label={chip.label || Translation.get('labels.none', 'common')}
                                    onDelete={() => removeFilter(chip)}
                                />
                            ))}
                            <span onClick={() => onClearAll()} className="generic-filter__chips__clear">
                                {Translation.get('filters.removeFilters', 'ui-base')}
                            </span>
                        </React.Fragment>
                    )}
                </div>
                {canCopyFilters && (
                    <Tooltip title="Copy filters to clipboard" arrow placement="right">
                        <IconButton size="small" onClick={onCopyFilters}>
                            <Icon fontSize="inherit">content_copy</Icon>
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default GenericFilterChips;
