import React from 'react';
import GenericFilterType from './type';
import '../styles/horizontal.scss';

/**
 * Display the filters that are placed horizontal, above the content.
 * @param {*} param0
 * @returns
 */
const GenericFilterHorizontal = ({ filterSetup, filters = {}, onChange, autoCompleteOptionsLength }) => {
    return (
        <div className="generic-filter__horizontal">
            {filterSetup &&
                filterSetup.map((filter, i) => (
                    <GenericFilterType
                        key={`filter-horizontal-${i}`}
                        filter={filter}
                        value={filters[filter.name] ? filters[filter.name] : undefined}
                        horizontal
                        onChange={onChange}
                        autoCompleteOptionsLength={autoCompleteOptionsLength || 12}
                        className="generic-filter__horizontal__filter"
                    />
                ))}
        </div>
    );
};

export default GenericFilterHorizontal;
