import React from 'react';
import Translation from 'components/data/Translation';
import '../styles/delete.scss';

/**
 * Display a delete option in the filter list for a specific filter.
 * @param {*} param0
 * @returns
 */
const GenericFilterDelete = ({ active, onDelete }) => {
    if (!active) return null;

    return (
        <div className="generic-filter__delete">
            <span onClick={() => onDelete()}>{Translation.get('filters.deleteSelection', 'ui-base')}</span>
        </div>
    );
};

export default GenericFilterDelete;
