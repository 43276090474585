import React, { CSSProperties } from 'react';
import Avatar from 'components/ui-components-v2/Avatar';

import '../styles/filter-item-details.scss';

interface Classes {
    root?: string;
    text?: string;
    image?: string;
}

interface Props {
    imageSrc?: string;
    text: string;
    textStyle?: CSSProperties;
    classes?: Classes;
}

/**
 * This component is responsible for rendering the filter item details within the generic filter components.
 */
const FilterItemDetails: React.FC<Props> = ({ imageSrc, text, textStyle, classes }) => {
    return (
        <div className={`filter-item-details ${classes?.root}`}>
            {imageSrc && (
                <Avatar size="small" className={classes?.image} src={imageSrc}>
                    {imageSrc}
                </Avatar>
            )}
            <span className={classes?.text} style={textStyle}>
                {text}
            </span>
        </div>
    );
};

export default FilterItemDetails;
