import React from 'react';
import '../styles/sidebar-container.scss';

interface Props {
    children: React.ReactNode | React.ReactNode[];
}

const FullScreenOverlaySidebarContainer = ({ children }: Props) => {
    return <div className="full-screen-overlay-sidebar-container">{children}</div>;
};

export default FullScreenOverlaySidebarContainer;
