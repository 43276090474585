import React from 'react';
import GenericFilterTypeSelect from './type-select';
import GenericFilterTypeHorizontalSelect from './type-horizontal-select';
import GenericFilterTypeSelectMultiple from './type-select-multiple';
import GenericFilterTypeAutocompleteMultiple from './type-autocomplete-multiple';
import GenericFilterTypeAutocomplete from './type-autocomplete';
import GenericFilterTypeRange from './type-range';
import GenericFilterTypeHorizontalSelectMultiple from './type-horizontal-select-multiple';
import GenericFilterTypeHorizontalAutocompleteMultiple from './type-horizontal-autocomplete-multiple';
import GenericFilterTypeHorizontalAutocomplete from './type-horizontal-autocomplete';
import GenericFilterTypeSelectMultipleSubs from './type-select-multiple-subs';
import GenericFilterTypeDatePicker from './type-date-picker';

/**
 * Determine which filter to render.
 * @param {*} param0
 * @returns
 */
const GenericFilterType = ({ filter, value, horizontal, maxOptionsShown, className, autoCompleteOptionsLength = 12, onChange }) => {
    const payload = {
        filter,
        value,
        maxOptionsShown,
        className,
        onChange
    };

    switch (filter.type) {
        case 'select':
            if (horizontal) {
                if (Array.isArray(filter.options) && filter.options.length > autoCompleteOptionsLength) {
                    return <GenericFilterTypeHorizontalAutocomplete {...payload} />;
                }
                return <GenericFilterTypeHorizontalSelect {...payload} />;
            }
            if (Array.isArray(filter.options) && filter.options.length > autoCompleteOptionsLength) {
                return <GenericFilterTypeAutocomplete {...payload} />;
            }
            return <GenericFilterTypeSelect {...payload} />;
        case 'selectMultiple':
            if (horizontal) {
                if (Array.isArray(filter.options) && filter.options.length > autoCompleteOptionsLength) {
                    return <GenericFilterTypeHorizontalAutocompleteMultiple {...payload} />;
                }
                return <GenericFilterTypeHorizontalSelectMultiple {...payload} />;
            }
            if (Array.isArray(filter.options) && filter.options.length > autoCompleteOptionsLength) {
                return <GenericFilterTypeAutocompleteMultiple {...payload} />;
            }
            return <GenericFilterTypeSelectMultiple {...payload} />;
        case 'selectMultipleSubs':
            return <GenericFilterTypeSelectMultipleSubs {...payload} />;
        case 'datePicker':
            return <GenericFilterTypeDatePicker {...payload} />;
        case 'range':
            if (horizontal) {
                return <div>TODO: horizontal range filter</div>;
            }
            return <GenericFilterTypeRange {...payload} />;
        default:
            console.warn('filter type not found:', filter.type);
            return null;
    }
};

export default GenericFilterType;
