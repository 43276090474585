import React from 'react';
import '../styles/working-area-actionbar.scss';

interface Props {
    children: React.ReactNode | React.ReactNode[];
}

const FullScreenOverlayWorkingAreaActionbar = ({ children }: Props) => {
    return <div className="full-screen-overlay-working-area-actionbar">{children}</div>;
};

export default FullScreenOverlayWorkingAreaActionbar;
