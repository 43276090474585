import React from 'react';
import { connect } from 'react-redux';
import Request from 'components/data/Request';
import Button from 'components/ui-components-v2/Button';
import EditorHistory from 'components/editor-data/EditorHistory';
import EditorData from 'components/editor-data/EditorData';
import Icon from 'components/ui-components-v2/Icon';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import EditorActions from 'reducers/Editor/actions';
import Translation from 'components/data/Translation';
import '../styles/campaign-actions.scss';

const FullScreenOverlayCampaignActions = (props) => {
    /**
     * Save the current state
     */
    const onSave = () => {
        // First set the lastUpdate and lastUpdatePrintable keys to the model
        if (props.onSave) {
            props.onSave();
        }

        const editor = EditorData.get();

        // Save campaign
        Request.post('editor/save', { id: editor.id, data: editor.data, versionNr: editor.versionNr }).then((data) => {
            props.save(data);
            SnackbarUtils.success('The campaign was saved');
        });
    };

    return (
        <div className="full-screen-overlay-campaign-actions">
            <Button
                onClick={() => EditorHistory.undo()}
                variant="text"
                size="small"
                startIcon={<Icon>undo</Icon>}
                className="full-screen-overlay-campaign-actions__btn">
                {Translation.get('actions.undo', 'common')}
            </Button>
            <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={onSave}
                endIcon={<Icon>save</Icon>}
                className="full-screen-overlay-campaign-actions__btn">
                {Translation.get('actions.save', 'common')}
            </Button>
        </div>
    );
};

export default connect(null, EditorActions)(FullScreenOverlayCampaignActions);
