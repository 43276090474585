import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import '../styles/type-navigation.scss'

const GenericFilterTypeNavigation = ({ filter, value}) => {
    const history = useHistory();

    const handleClick =  (option) => {
        if (!!option.action && typeof option.action === 'function') {
            option.action();
        }
        else if (!!option.path && typeof option.path === 'string') {
            history.push(option.path);
        }
    }

    return (
        <div className="generic-filter__type-navigation">
            {filter.options.map(option =>
                <div
                    className={classNames('generic-filter__type-navigation__item', {
                        'generic-filter__type-navigation__item--active': option.key === value
                    })}
                    key={option.key}
                    data-tour-selector={option.label}
                    onClick={() => handleClick(option)}
                >
                    <div className="generic-filter__type-navigation__item__left">
                        {option.icon &&
                            <div className="generic-filter__type-navigation__item__icon">{option.icon}</div>
                        }
                        {option.label}
                    </div>
                    {typeof option.count !== 'undefined' &&
                        <div className="generic-filter__type-navigation__item__right">
                            {option.count}
                        </div>
                    }
                </div>
            )}
        </div>
    )
}

export default GenericFilterTypeNavigation;
