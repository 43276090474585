import React, { useContext } from 'react';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import { DarkContext } from 'components/ui-components/Dark';
import '../styles/sidebar-title.scss';

interface Props {
    title: string;
    onClose?: () => void;
}

const FullScreenOverlaySidebarTitle = ({ title, onClose }: Props) => {
    const dark = useContext(DarkContext);
    return (
        <div
            className={classNames('full-screen-overlay-sidebar-title', {
                'full-screen-overlay-sidebar-title--dark': dark
            })}>
            <div
                className={classNames('full-screen-overlay-sidebar-title__copy', {
                    'full-screen-overlay-sidebar-title__copy--dark': dark
                })}>
                {title}
            </div>
            {onClose && (
                <IconButton aria-label="Close" onClick={onClose} size="small">
                    <Icon>close</Icon>
                </IconButton>
            )}
        </div>
    );
};

export default FullScreenOverlaySidebarTitle;
