import React, { useEffect } from 'react';
import useRemoveBodyScroll from 'hooks/useRemoveBodyScroll';
import { DarkProvider } from 'components/ui-components/Dark';
import FullScreenOverlayToolbar from './toolbar';
import '../styles/main.scss';

interface Props {
    title: string;
    description?: string;
    onClose?: () => void;
    leftButtons?: React.ReactNode;
    centerButtons?: React.ReactNode | React.ReactNode[];
    rightButtons?: React.ReactNode;
    tourData?: string;
    dark?: boolean;
    children: React.ReactNode | React.ReactNode[];
}

const FullScreenOverlay = ({
    title = '',
    description,
    onClose,
    leftButtons = null,
    centerButtons = null,
    rightButtons = null,
    tourData,
    children,
    dark = false
}: Props) => {
    // Call hook to lock body scroll
    useRemoveBodyScroll();

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    /**
     * Close the overlay on escape key press
     * @param event - KeyboardEvent
     */
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key !== 'Escape') return;

        if (onClose) onClose();
    };

    return (
        <DarkProvider value={Boolean(dark)}>
            <div className="full-screen-overlay" data-mui-color-scheme={dark ? 'dark' : 'light'}>
                <FullScreenOverlayToolbar
                    title={title}
                    description={description}
                    onClose={onClose}
                    tourData={tourData}
                    leftButtons={leftButtons}
                    centerButtons={centerButtons}
                    rightButtons={rightButtons}
                />
                <div className="full-screen-overlay__content">{children}</div>
            </div>
        </DarkProvider>
    );
};

export default FullScreenOverlay;
