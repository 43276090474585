import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from 'components/ui-components-v2/Radio';
import GenericFilterShowMore from './show-more';
import GenericFilterDelete from './delete';

import '../styles/type-select.scss';

/**
 * Display a filter of type select in the vertical list with one option selectable
 * @param {*} param0
 * @returns
 */

const GenericFilterTypeSelect = ({ filter, value = [], maxOptionsShown, onChange }) => {
    const [showOptions, setShowOptions] = useState(maxOptionsShown);

    const handleChange = (e) => {
        onChange(filter.name, e.target.value);
    };

    /**
     * Toggle the show more option
     * @param {boolean} show
     */
    const toggleShowMore = (show) => {
        if (show) {
            setShowOptions(filter.options.length);
        } else {
            setShowOptions(maxOptionsShown);
        }
    };

    return (
        <div>
            <FormControl component="fieldset" className="generic-filter__type-select">
                <RadioGroup name={filter.name} value={value} onChange={(e) => handleChange(e)}>
                    {filter.options.map((option, i) => {
                        if (i >= showOptions) return null;
                        return (
                            <div key={i} className="generic-filter__type-select__option">
                                <FormControlLabel value={option.key} control={<Radio color="default" />} label={option.value || 'None'} />
                                {option.count && <div className="generic-filter__type-select-multiple__count">{option.count}</div>}
                            </div>
                        );
                    })}
                </RadioGroup>
            </FormControl>
            {filter.options.length > maxOptionsShown && <GenericFilterShowMore showMore={showOptions !== maxOptionsShown} onToggleShowMore={toggleShowMore} />}
            <GenericFilterDelete onDelete={() => onChange(filter.name, '')} active={value.length > 0} />
        </div>
    );
};

export default GenericFilterTypeSelect;
