import React from 'react';
import classNames from 'classnames';
import Dark, { DarkProvider } from 'components/ui-components/Dark';
import '../styles/working-area.scss';

interface Props {
    showSidebar?: boolean;
    dark?: boolean;
    children: React.ReactNode | React.ReactNode[];
}

const FullScreenOverlayWorkingArea = ({ showSidebar, dark, children }: Props) => {
    return (
        <DarkProvider value={Boolean(dark)}>
            <Dark dark={dark}>
                <div
                    data-mui-color-scheme={dark ? 'dark' : 'light'}
                    className={classNames('full-screen-overlay-working-area', {
                        'full-screen-overlay-working-area--sidebar': showSidebar,
                        'full-screen-overlay-working-area--dark': dark
                    })}>
                    {children}
                </div>
            </Dark>
        </DarkProvider>
    );
};

export default FullScreenOverlayWorkingArea;
