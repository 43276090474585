import React from 'react';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import SearchField from 'components/ui-components/SearchField';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import Loader from 'components/ui-components/Loader';
import GenericFilterFilter from './filter';
import '../styles/list.scss';

/**
 * Display filters in a vertical list.
 */
class GenericFilterList extends React.Component {
    render() {
        const {
            filterSetup,
            filters = {},
            expandAllFilters,
            maxOptionsShown,
            hideHeader,
            inDialog,
            searchField,
            searchTerm,
            searchPlaceholder,
            forceSearchTerm,
            filtersLoading,
            className,
            onClose,
            onChange
        } = this.props;

        return (
            <div className={classNames('generic-filter__list', className)} data-tour-selector="generic-filter-list">
                <Loader isLoading={filtersLoading}>
                    {!hideHeader && (
                        <div className="generic-filter__list__header">
                            <Typography variant="h4">{Translation.get('filters.filters', 'ui-base')}</Typography>
                            <IconButton onClick={onClose} size="small" className="generic-filter__list__header__btn">
                                <Icon>{inDialog ? 'arrow_circle_right' : 'close'}</Icon>
                            </IconButton>
                        </div>
                    )}
                    {searchField && (
                        <SearchField
                            searchTerm={searchTerm}
                            onChange={onChange}
                            searchPlaceholder={searchPlaceholder}
                            forceSearchTerm={forceSearchTerm}
                            className="generic-filter__list__search"
                        />
                    )}
                    {filterSetup &&
                        filterSetup.map((filter, i) => (
                            <GenericFilterFilter
                                key={`filter-filter-${filter.name}`}
                                filter={filter}
                                maxOptionsShown={maxOptionsShown}
                                value={filters[filter.name] ? filters[filter.name] : undefined}
                                defaultExpanded={expandAllFilters ? true : i === 0}
                                autoCompleteOptionsLength={20}
                                onChange={onChange}
                            />
                        ))}
                </Loader>
            </div>
        );
    }
}

export default GenericFilterList;
